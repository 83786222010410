@import '../../styles/custommixin';
.gwSpaWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.gwSpaWrapper__tabs {
  flex-grow: 0;
  flex-shrink: 0;
  &--ahub{
    & .gwTab{
        padding: 8px 3.2rem 0 3rem;
        background: transparent;
        border-bottom: none;
        width: 65%;
        position: fixed;
        z-index: 9999;
        margin: 0 auto;
        left: 0;
        right: 0;
        button {
          display: none;
        }
        &items {
            &__link {
                font-size: 1rem;
                @include OpenSansRegular;
                color: #333333;
                &.active {
                    border-bottom: 3px solid #EA067E;
                    @include OpenSansBold;
                    color: #333333;
                    &:hover {
                        border-bottom: 3px solid #EA067E;
                        
                    }
                }
            }
        }
    }
  }
}

.gwSpaWrapper__iframe {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  padding-top:125px; 
  @include bp-small {
    padding-top: 154px;
  }
  &--ahub{
    padding-top:62px;
    @include bp-verysmall {
      padding-top:122px;
    }
  }
}


