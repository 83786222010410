.gwSpaWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.gwSpaWrapper__tabs {
  flex-grow: 0;
  flex-shrink: 0;
}

.gwSpaWrapper__error__iframe {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  padding-top:65px; 
}
