@import '../../styles/custommixin';
@import '../../styles/customvariables';
@import "~common-components-spa/dist/styles/main.scss";

.gwUxContainer{
    background: #f5f5f5;
    height: calc(100vh - 61px);
    .gwUx__center-blk{
        @include absolute(top 50% left 50%);
        width: 80%;
        transform: translate(-50%,-50%);
        &--text{
            @include PlayfairRegular;
            font-size: 1.5rem;
            @include bp-extrasmall{
                font-size: 1.25rem;
            }
        }
        &--img{
            padding-top: 5rem;
            @include bp-medium{
                padding-top: 0rem;
            }
        }
        @include bp-small{
            top:80%;
        }
    }
    .gwUx__left-blk{
        @include absolute(top 50% left 50%);
        width: 80%;
        transform: translate(-50%,-50%);
        @include bp-large{
            max-width: 100%;
            img{
                width: 100%;
            }
        }
        @include bp-small{
            text-align: center;
            padding-top:2rem;
            img{
                width: auto;
            }
        }
    }
}

@media only screen and (max-width: 767px) and (min-width: 450px){
    .gwUxContainer{
        height: 150vh;
    }

    
}

@media only screen and (max-width: 767px) {
    .gwProgressbar{
        @include absolute(top 97px);
    }
}