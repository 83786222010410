.no-activehub-account-error {
    display: flex;
    flex-flow: column;
    // align-items: center;
    gap: 1.25rem;
    width: 40%;

    img {
        width: 250px;
        block-size: auto;
        align-self: center;
    }

    h1 {
        font-size: 1rem;
        font-weight: 700;
        align-self: center;
    }

    ul {
        list-style: disc;
        padding-left: 1.5rem;
        font-size: 0.875rem;
    }
    .reset-password {
        text-decoration: underline;
    }
    footer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .button__v2 {
            margin-right: 20px;
            border-radius: 16px;
            font-size: 0.875rem;
        }
    }
    p {
        font-size: 0.875rem;
    }
}