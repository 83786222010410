/////////// MIXIN RULES ///////////
// Mixin Functions //
@mixin OpenSansLight {
    font-family: 'Open Sans', serif;
    font-weight: 300;
}
@mixin OpenSansRegular {
    font-family: 'Open Sans';
    font-weight: 400;
  }
  @mixin OpenSansSemibold {
    font-family: 'Open Sans';
    font-weight: 600;
  }
  @mixin OpenSansBold {
    font-family: 'Open Sans';
    font-weight: 700;
  } 
  @mixin OpenSansExtraBold {
    font-family: 'Open Sans';
    font-weight: 800;
  }
  @mixin PlayfairRegular {
    font-family: 'Playfair Display';
    font-weight: 400;
  }
  @mixin MaterialIcons {
    font-family: 'Material Icons';
    font-style: normal;
  }
  
@mixin overlay() {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

// inclusion rule : @include overlay();
@mixin center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

// inclusion rule : @include center-block;
@mixin text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// inclusion rule : @include text-truncate;
@mixin flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

// inclusion rule : @include flex
@mixin inline-flex {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

// inclusion rule : @include inline-flex;
// Mixin Border Radius //
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

// inclusion rule : @include border-radius(2px);
// Mixin Box Shadow //
@mixin box-shadow($shadow) {
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    -ms-box-shadow: $shadow;
    box-shadow: $shadow;
}

// inclusion rule : @include box-shadow(0px 3px 6px 0px #bdbdbd);
// Mixin Box Sizing //
@mixin box-sizing($box-model) {
    -webkit-box-sizing: $box-model;
    -moz-box-sizing: $box-model;
    -ms-box-sizing: $box-model;
    box-sizing: $box-model;
}

// inclusion rule : @include box-sizing(border-box);
// Font Face //
@mixin font-face($font-name, $file-name, $weight: normal, $style: normal) {
    @font-face {
        font-family: quote($font-name);
        src: url($file-name + '.eot');
        src: url($file-name + '.eot?#iefix') format('embedded-opentype'),
        url($file-name + '.woff') format('woff'),
        url($file-name + '.ttf') format('truetype'),
        url($file-name + '.svg##{$font-name}') format('svg');
        font-weight: $weight;
        font-style: $style;
    }
}

// inclusion rule : @include font-face('gotham', '/fonts/gotham')
// Mixin Font Size //
@function calculateRem($size) {
    $remSize: $size / 16px;
    @return $remSize * 1rem;
}

@mixin font-size($size) {
    font-size: $size;
    font-size: calculateRem($size);
}

// inclusion rule : @include font-size(14px)

// Mixin Breakpoints //
@mixin bp-extralarge {
    @media only screen and (max-width: 1366px) {
        @content;
    }
}

@mixin bp-large {
    @media only screen and (max-width: 1200px) {
        @content;
    }
}

@mixin bp-extramedium {
    @media only screen and (max-width: 1024px) {
        @content;
    }
}

@mixin bp-medium {
    @media only screen and (max-width: 991px) {
        @content;
    }
}

@mixin bp-small {
    @media only screen and (max-width: 767px) {
        @content;
    }
}

@mixin bp-verysmall {
    @media only screen and (max-width: 576px) {
        @content;
    }
}

@mixin bp-extrasmall {
    @media only screen and (max-width: 360px) {
        @content;
    }
}

// inclusion rule : @include bp-small { }
// Mixin transform and Transition //
@mixin transform($property...) {
    -webkit-transform: $property;
    -ms-transform: $property;
    transform: $property;
}

// inclusion rule : @include transform(rotate(30deg)); }
@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

// inclusion rule : @include transition(color .3s ease); }
@mixin appearance ($value) {
    -webkit-appearance: $value;
    -moz-appearance: $value;
    appearance: $value;
}

// inclusion rule : @include appearance(none); }
// Animations and keyframes //
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

// inclusion rule
// @include keyframes(slide-down) {
//  0% { opacity: 1; }
//  90% { opacity: 0; }
// }
// .element {
//  width: 100px;
//  height: 100px;
//  background: black;
//  @include animation('slide-down 5s 3');
// }

// Cross browser opacity //
@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
}
// inclusion rule : @include opacity(0.8)

// Clearfix //
%clearfix {
    *zoom: 1;
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

// inclusion rule : @extend %clearfix;
//  ----- Support for IE 7-8-9 -----
// You will need to add conditional classes on your <html> element
@mixin forIE( $ieVersion) {
    .ie#{$ieVersion} & {
        @content;
    }
}

// inclusion rule @include forIE(8) {
//    background-color: #fadade;
// }
// Positioning Mixins //
@mixin position($position, $args) {
    @each $o in top right bottom left {
        $i: index($args, $o);
        @if $i and $i+1 <=length($args) and type-of(nth($args, $i + 1))==number {
            #{$o}: nth($args, $i + 1);
        }
    }
    position: $position;
}

// Positioning helpers
@mixin absolute($args: '') {
    @include position(absolute, $args);
}

@mixin fixed($args: '') {
    @include position(fixed, $args);
}

@mixin relative($args: '') {
    @include position(relative, $args);
}

// inclusion rule
// .menu li { @include relative; }
// .sub-menu { @include absolute(top 100% left 0); }
// .sticky-bar { @include fixed(top 0 left 0); }
// Define vertical, horizontal, or both position //
@mixin center($position) {
    position: absolute;
    @if $position=='vertical' {
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    @else if $position=='horizontal' {
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translate(-50%);
    }
    @else if $position=='both' {
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}

// inclusion rule : @include center(both)

// Mixin for Modal Popup
@mixin modal-popup() {
    .modal {
        &__content {
            border: 0px;
            border-radius: 0px;
            padding: 30px 30px;
        }
        &__dialog {
            max-width: 600px;
        }
        &__body {
            padding: 0;
        }
        &__footer {
            border-top: 0px;
            padding: 0;
        }
    }
}

//include rule : @include modal-popup();
// Mixin for Ripple Effect
@mixin ripple-effect() {
    .ripple {
        position: relative;
        overflow: hidden;
        transform: translate3d(0, 0, 0);
        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            pointer-events: none;
            background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
            background-repeat: no-repeat;
            background-position: 50%;
            transform: scale(10, 10);
            opacity: 0; //transition: ;
            @include transition(transform .5s, opacity 1s);
        }
        &:active:after {
            transform: scale(0, 0);
            opacity: .3;
            transition: 0s;
        }
        &:active {
            background-size: auto;
            transition: background 0s;
        }
    }
}

//include rule : @include ripple-effect();
// Mixin for Material Animation //
@mixin inputHighlighter {
    @-webkit-keyframes inputHighlighter {
        from {
            background: #57b3a6;
        }
        to {
            width: 0;
            background: transparent;
        }
    }
    @-moz-keyframes inputHighlighter {
        from {
            background: #57b3a6;
        }
        to {
            width: 0;
            background: transparent;
        }
    }
    @keyframes inputHighlighter {
        from {
            background: #57b3a6;
        }
        to {
            width: 0;
            background: transparent;
        }
    }
}

//include rule : @include rotation();
@mixin rotation{
    @-webkit-keyframes rotation {
        from {
                -webkit-transform: rotate(0deg);
        }
        to {
                -webkit-transform: rotate(359deg);
        }
    }
    @-moz-keyframes inputHighlighter {
        from {
            -webkit-transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(359deg);
        }
    }
    @keyframes inputHighlighter {
        from {
            -webkit-transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(359deg);
        }
    }
}

@keyframes radioripple {
    0% {
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
    }
    50% {
        box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.1);
    }
    100% {
        box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0);
    }
}

// Mixin form overlay //
@mixin form-overlay() {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
}

// inclusion rule : @include form-overlay();

// Mixin User select
@mixin user-select($value) {
    -webkit-touch-callout: $value;
    -webkit-user-select: $value;
    -khtml-user-select: $value;
    -moz-user-select: $value;
    -ms-user-select: $value;
    user-select: $value;
}

//include rule : @include user-select(none);
//Mixin background clip
@mixin background-clip($clip) {
    -webkit-background-clip: $clip;
    -mox-background-clip: $clip;
    -o-background-clip: $clip;
    background-clip: $clip;
}

//include rule : background-clip(padding-box);


//Mixin Horizontal Scrollbar
@mixin hscrollbars() {
    scrollbar-color:#ccc rgba(0,0,0,.1);
    scrollbar-width: thin;
    
    // Custom scrollbar css
    &::-webkit-scrollbar {
        width: 5px;
        height: 4px;
        background: #f7f6f6;
    } 
    
    // Track 
    &::-webkit-scrollbar-track:hover {
        @include box-shadow(inset 0 0 5px #dddddd);
        @include border-radius(5px);
        background: #d8d5d5;
        @include border-radius(5px);
        visibility: visible;
    }
    
    // Handle 
    &::-webkit-scrollbar-track {
        background: #d8d5d5;
        @include border-radius(5px);
        visibility: hidden;
    }
    
    // Handle on hover 
    &::-webkit-scrollbar-thumb {
        background-color: #adadad;
        @include border-radius(5px);
        margin-top: 40px;
    }
}
//include rule : @include hscrollbars();
    
    
//Mixin vertical Scrollbar
%vscrollbar {
        scrollbar-color:#ccc rgba(0,0,0,.1);
        scrollbar-width: thin;

        // Custom scrollbar css
        &::-webkit-scrollbar {
        width: 4px;
        height: 10px;
        background: #f7f6f6;
    } 
    
    // Track 
    &::-webkit-scrollbar-track:hover {
        @include box-shadow(inset 0 0 5px #dddddd);
        @include border-radius(5px);
        background: #d8d5d5;
        @include border-radius(5px);
        visibility: visible;
    }
    
    // Handle 
    &::-webkit-scrollbar-track {
        background: #d8d5d5;
        @include border-radius(5px);
        visibility: hidden;
    }
    
    // Handle on hover 
    &::-webkit-scrollbar-thumb {
        background-color: #adadad;
        @include border-radius(5px);
        margin-top: 40px;
    }
}
//include rule : @include vscrollbars();
//Mixin vertical Scrollbar
@mixin vscrollbars() {
        @extend %vscrollbar;
    }    
    @mixin selectscroll(){
        @extend %vscrollbar;
        scrollbar-color:#C7C7C7;
        &::-webkit-scrollbar-thumb {
            background-color: #C7C7C7;
        }
    }
