@import "../../styles/customvariables";
@import "../../styles/custommixin";
@import "~common-components-spa/dist/styles/main.scss";
@import "~common-components-spa/dist/styles/reset.scss";

.gwProfile {
  display: inline-block;
  &__dropcontent {
    @include absolute(top 65px right -270px);
    // min-width: 155px;
    min-width: 230px;
    max-width: 230px;
    background: $pearson-white;
    font-size: 0.813rem;
    background: $pearson-white;
    color: $pearson-night-rider;
    padding: 3px 5px;
    border: solid 1px #E7E7E7;
    @include transition(right 0.5s ease-in-out);
    & a {
      //border-bottom: 1px solid $pearson-lavender;
    //   padding: 8px 10px;
      color: $pearson-night-rider;
      text-transform: capitalize;
      display: block;
      &:last-child {
        border-bottom: 0;
      }
    }
    .wpspc{
      word-break: break-word;
      font-size: $fontsize;
      .username{
        @include  OpenSansSemibold();
        padding: 8px 10px;
      }
      .userinfo{
        @include OpenSansRegular();
        padding: 8px 10px;
        span{
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          width: 210px;
          overflow: hidden;
          position: relative;
          display: inline-block;
          text-overflow: ellipsis;
          white-space: nowrap;
          // @include multi-line-ellipsis(1);
        }
      }
    }
    .details{
      @include OpenSansRegular();
      font-size: $fontsize;
      .nav-link:hover {
        background-color: $pearson-moonlight;
      }
    }
  }
  &drop__visible {
    right:1px;
  }
  &__dropicon {
    padding-right: 10px;
  }
  &__logout {
    padding: 10px 0px;
    color: $pearson-night-rider;
    text-transform: capitalize;
    display: block;
    & .usermenu__dropicon {
      width: 34px;
      height: 25px;
      padding-right: 18px;
    }
  }
  &-dropcontent {
    cursor: pointer;
  }
}
.gwAvatar {
  color: $pearson-night-rider;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
  &__img-blk {
    width: 46px;
    height: 48px;
    cursor: pointer;
    overflow: hidden;
    @include relative;
    background: #003057;
    cursor: pointer;
    @include border-radius(50%);
  }
  &__image {
    width: 100%;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    object-fit: contain;
    cursor: pointer;
  }
  &__text {
    color: $pearson-white;
    @include absolute(top 50% left 50%);
    font-size: 1.250rem;
    cursor: pointer;
    user-select: none;
    @include transform(translate(-50%, -50%));
    margin:0;
  }
}

@include keyframes(profileopen) {
  from {right: 0px;}
  to {right: 0px;}
}

@include keyframes(profileclose) {
  from {right: 0px;}
  to {right: -150px;}
}

.gwHeader--ahub{
  & .gwAvatar{
    &__img-blk {
      background: #EA067E;
      width: 40px;
      height: 40px;
    }
    &__text {
      @include OpenSansSemibold();
      font-size: 1rem;
    }
  }
}
