@import '~common-components-spa/dist/styles/custommixin';
@import "~common-components-spa/dist/styles/customvariables";

.upload-blk{
    display: inline-block;
    text-align: center;
    button {
        padding: unset;
        &:focus {
            outline: 2px solid #0B73DA;
            outline-offset: 1px;
            box-shadow: none;
        }
    }
    &__btn{
        width:160px;
        height: 160px;
        @include border-radius(50%);
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__txt{
        .upload-title{
            font-size: $fontsize;
            color: #252525;
            padding-top:10px;
        }
        .upload-desc{
            font-size: 12px;
            color: #707070;
            display: block;
            padding-top: 8px;
            margin:0;
        }
    }
}
