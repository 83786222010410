@import '../../styles/custommixin';
@import '../../styles/customvariables';


    .ogaWrapper {
        display: grid;
        grid-template-rows: minmax(80px, auto);
        background-color: transparent;
        height: 100%;
        width: 100%;
        transition: ease-in-out 0.5s;
        position: relative;
    
        &__bdy {
            padding: 0 25px 25px 25px;
            display: grid;
            grid-template-rows: minmax(70px, auto);
        }
    }
    
    .instructor {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 8px #0000000D;
        border: 1px solid #E9E9E9;
        border-radius: 4px;
        height: 100%;
    
        &__head {
            padding: 16px 24px;
            border-bottom: 0.5px solid #f0f0f0;
    
            &__txt {
                font-size: 1.250rem;
                @include OpenSansSemibold();
                color: $default-pearson-blue;
                text-align: left;
    
                &--small {
                    font-size: 1rem;
                }
    
                &.-textstylechange {
                    @include OpenSansRegular();
                    color: $pearson-cerulean;
                }
            }
    
            &resource {
                &__txt {
                    font-size: $fontsize;
                    @include OpenSansSemibold();
                    color: #252525;
                }
            }
        }
    
        &__body {
            padding: 24px;
    
            &__search {
                height: 270px;
                position: relative;
                width: 100%;
            }
    
            .datepicker {
                .form__input {
                    // background: url('../../../public/images/date-picker.svg') no-repeat;
                    background-position: 95% center;
                    margin-right: 10px;
                }
            }
        }
    
        &__foot {
            border-top: 0.5px solid#efefef;
            padding: 16px 24px;
    
            &__data {
                font-size: $fontsize;
                color: #252525;
                @include OpenSansRegular();
    
                & span {
                    @include OpenSansSemibold();
                }
            }
    
            .pagination__txt {
                border-bottom: 1px solid $pearson-charcoal;
            }
        }
    
        &__tab {
            &__head {
                padding: 1rem 0rem;
                border-bottom: 0.5px solid #e8e8e8;
            }
    
            &__body {
                margin-top: 24px;
            }
    
            &__title {
                text-align: left;
                letter-spacing: 0px;
                color: #252525;
                opacity: 1;
                font-size: 1rem;
    
                & span {
                    @include OpenSansSemibold();
                }
            }
    
            &__icon {
                width: 18px;
                height: 18px;
            }
    
            &__btn {
                background-color: $transparent;
                line-height: 0;
            }
        }
    
        &__link {
            color: #007FA3;
            font-size: $fontsize;
            @include OpenSansSemibold();
            text-decoration: underline;
            white-space: nowrap;
            margin-right: 12px;
            cursor: pointer;
            padding: 4px 0;
    
            &:last-child {
                margin-right: 0px;
            }
        }
    
        &__label {
            font-size: 0.750em;
            color: #252525;
            @include OpenSansRegular();
            margin: 16px 0 0 12px;
        }
    
        .button--v2 {
            background: $transparent;
            border: $transparent;
            cursor: pointer;
            display: inline-block;
            vertical-align: middle;
            border-radius: 40px;
            padding: 0 20px;
            font-size: $small-fontsize-button;
            line-height: $small-lineheight-button;
            min-height: 32px;
            @include OpenSansSemibold;
    
            &:last-child {
                margin: 0 0 0 12px;
            }
    
            &.btnwidth {
                min-width: 130px;
                min-height: 40px;
            }
    
            &.rosterbtn {
                min-width: 128px;
                min-height: 32px;
                line-height: 30px;
            }
    
            &.instructorbtn {
                min-width: 114px;
                line-height: 30px;
            }
    
            &.resourcesbtn {
                min-width: 156px;
                line-height: 36px;
            }
    
            &.modalbtn {
                min-width: 130px;
                font-size: $fontsize;
            }
    
            &.dropdownbtn {
                min-width: 110px;
                font-size: $fontsize;
            }
    
            &.assignmentbtn {
                min-width: 80px;
                font-size: $fontsize;
            }
    
            &.small {
                font-size: 14px;
            }
    
            &.btnsquare {
                @include border-radius(4px);
            }
    
            &.locale-btn {
                color: #252525;
                @include OpenSansRegular;
                border: 1px solid #007FA3;
            }
    
            &.btn36 {
                height: 36px;
            }
    
            &.btnIcon {
                margin-right: 15px;
                padding: 0px;
    
                &:last-child {
                    margin: 0px;
                }
            }
    
            &.roster-land-btn {
                color: #252525;
                border: 1px solid #007FA3;
                border-radius: 8px;
                font-size: $fontsize;
            }
    
            &__primary {
                background-color: $primary-button-bgr;
                border: 1px solid $primary-button-border;
                color: $primary-button-color;
    
                &:disabled,
                &.disable {
                    background-color: #b2d8e3;
                    border-color: #b2d8e3;
                    pointer-events: none;
                }
    
                &:before {
                    background: $primary-button-bg-hover;
                }
    
                &:hover {
                    color: $primary-button-color;
    
                    &:before {
                        background-color: $primary-button-bg-hover;
                    }
                }
    
                &__bordered {
                    border: 1px solid $primary-button-border;
                    background-color: #fff;
                    color: $primary-button-bgr;
    
                    &:before {
                        background: $primary-button-bg-hover;
                    }
    
                    &:hover {
                        color: $primary-button-color;
    
                        &:before {
                            background: $primary-button-bg-hover;
                        }
                    }
    
                    &:disabled,
                    &.disable {
                        border-color: #007FA3;
                        pointer-events: none;
                        color: #007FA3;
                        opacity: 0.5;
                    }
                }
            }
    
            &__secondary {
                background-color: #FFCB4E;
                border: 1px solid #FFCB4E;
                color: $pearson-charcoal;
                font-size: $fontsize;
    
                &:disabled,
                &.disable {
                    pointer-events: none;
                    opacity: 0.5;
                }
    
                &:before {
                    background: #ffe15c;
                }
    
                &:hover {
                    border: 1px solid #ffe15c;
    
                    &:before {
                        background-color: #ffe15c;
                    }
                }
    
                @include ripple-effect();
            }
    
            &__bordered {
                background-color: $bordered-button-bg;
                border: 1px solid $bordered-button-border;
                color: $bordered-button-color;
    
                &:before {
                    background: $bordered-button-bg;
                }
    
                &:hover {
                    border: 1px solid $bordered-button-border;
    
                    &:before {
                        background: $bordered-button-hover-color;
                    }
                }
    
                &:disabled,
                &.disable {
                    pointer-events: none;
                    opacity: 0.5;
                }
            }
    
            &__default {
                background-color: $default-button-bg;
    
                &.access {
                    background-color: #D9D9D900;
                    border: 1px solid #6A7070;
    
                    &:before {
                        background-color: #D9D9D94D;
                    }
    
                    &:hover {
                        background-color: #D9D9D900;
                        border: 1px solid #6A7070;
    
                        &:before {
                            background-color: #D9D9D94D;
                        }
                    }
                }
    
                border: 1px solid $default-button-border;
                color: $default-button-color;
    
                &:hover {
                    background-color: $default-button-bg-hover;
                    border: 1px solid $default-button-hover-border;
                    color: $default-button-color;
                }
    
                &:disabled,
                &.disable {
                    pointer-events: none;
                    opacity: 0.5;
                }
            }
    
            &__img {
                margin: 0 0 0 15px;
            }
    
            &__link {
                padding: 0;
                font-weight: 400;
                font-size: 0.875rem;
                overflow: visible !important;
            }
    
        }
    
    }
    
    .organisation__cnt {
        width: 720px;
        height: 80px;
        background: pears padding-box;
        box-shadow: 0px 1px 16px #0000001A;
        border-radius: 6px;
        margin: 0 auto 18px;
    
        &txt {
            @include OpenSansRegular();
            font-size: 1.500rem;
            color: #252525;
            margin: 0 24px;
        }
    
        &img {
            max-height: 80px;
            width: auto;
        }
    }
    
    .no__boxshadow {
        box-shadow: none;
        border: none;
    }
    
    .vl {
        border: 1px solid #E9E9E9;
    }
    
    .redeem {
        &__samplecode {
            color: $pearson-charcoal;
            font-size: 0.75rem;
            @include OpenSansRegular();
            margin-top: 8px;
        }
    
        .popupacces {
            color: #007FA3;
            text-decoration: underline;
            font-size: $fontsize;
            cursor: pointer;
            @include OpenSansRegular();
        }
    
        .form-group {
            margin-bottom: 0;
        }
    }
    
    .border-left {
        border-left: 1px solid #dee2e6 !important;
    }

    .header-container {
        padding: 25px;
    }
    
    .links {
        color: #007FA3;
        text-decoration: underline;
    }
    
    .justify-content-center {
        justify-content: center !important;
    }
    
    .show-cursor {
        cursor: pointer;
    }
    
    .link-container {
        width: 70%;
        text-align: center;
    }
    
    .link-text-first {
        width: 40%;
        float: left;
        text-align: right;
    }
    
    .link-text-second {
        float: left;
    }
    
    .modal__content-blk-v2 {
        h6 {
            padding-top: 65px;
        }
    }
    
    .modal__body {
        &.modal-body {
            background-position: center;
        }
    }
    
    .modal__footer {
        hr {
            width: 100%;
        }
    }
    
    .ogaNav {
        &--v2{ 
            &+.ogaWrapper {
                padding-left: 230px;
    
                .ogaWrapper__cnt {
                    width: calc(100% - 230px);
                    padding: 25px 25px 0;
                    position: fixed;
                    z-index: 3;
                    background-color: #f9f9f9;
                    transition: ease-in-out 0.5s;
                }
            }
        }
        &__collapsed {
            min-width: 60px;
            width: 60px;
            &+.ogaWrapper {
                padding-left: 60px;
                .ogaWrapper__cnt {
                    width: calc(100% - 60px);
                }
            }
        }
    }



