@import './../../styles/custommixin';
@import './../../styles/customvariables';

.selectDropdown{
    &__drop,.dropdown-menu{
        font-size: $fontsize;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border:0;
        min-width: 124px;
        transform: translate3d(0px, 31px, 0px) !important;
        border-radius: 0px 0px 4px 4px;
        &__wrp{
            max-height: 230px;
            overflow-y: auto;
            @include selectscroll();
            margin:5px;
        }
        &__item,.dropdown-item{
            padding: 3px 11px;
            @include OpenSansRegular;
            &:active, &.active{
                background: #fff;
                color: #252525;
            }
            &:hover,&:focus{
                background: transparent;
            }
        }
    }
    &__btn,.btn-primary{
        background: transparent;
        border: transparent;
        color: #252525;
        font-size: $fontsize;
        @include OpenSansSemibold;
        min-width: 124px;
        text-align: left;
        border-radius: 0;
        &:hover, &:focus, &:active{
            background: transparent;
            border: transparent;
            color: #252525;
        }
        &:focus{
            outline: 0;
            outline-offset: 0; 
            box-shadow: auto;
        }
        &:not(:disabled):not(.disabled):active{
            background: transparent;
            border: transparent;
            color: #252525;
        }
        &:after{
            border:0;
            background-repeat: no-repeat;
            width: 10px;
            background-size: 8px;
            height: 10px;
            margin-left: 12px;
            vertical-align: middle;
            background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23707070%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23707070%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
        }
    } 
    &.show{
        .selectDropdown__btn, .btn-primary.dropdown-toggle{
            background: transparent;
            border: transparent;
            color: #252525;
            @include box-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
        }
    }
}