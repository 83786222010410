@import "../../styles/customvariables";
@import "../../styles/custommixin";
@import "~common-components-spa/dist/styles/main.scss";
@import "~common-components-spa/dist/styles/reset.scss";

@mixin dropdown-list {
  border-bottom: 1px solid $pearson-lavender;
  padding: 5px 10px;
  color: $pearson-night-rider;
  text-transform: capitalize;
  display: block;
  &:last-child {
    border-bottom: 0;
  }
}

.gwHeader {
  background-color: $pearson-white;
  width: 100%;
  z-index: 9999;
  @include fixed;
  @include bp-small {
    min-height: 62px;
  }
  &__ahub {
    height: 62px;
    box-shadow: 0px 9px 25px rgba(0, 0, 0,0.08);
}
  &Logo {
    margin: 14px 0;
    max-height: 37px;
    &__img {
      max-height: 36px;
      width: auto;
      cursor: pointer;
      @include bp-verysmall {
        max-height: 29px;
      }
      @include bp-extrasmall {
        max-height: 22px;
      }
    }
    & .mbnone{
      display: block;
      @include bp-large{
        display: none;
      }
    }
    &__note {
      margin: 0 30px;
      user-select: none;
      @include bp-small {
        margin: 0 0 0 15px;
      }
      @include bp-small {
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        margin: 0 auto;
        padding: 0 15px;
        width: 100%;
        text-align: center;
      }
      &txt {
        @include PlayfairRegular;
        font-size: 2rem;
        color: $default-pearson-blue;
        @include bp-medium {
          font-size: 1.25rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 250px;
        }
        @include bp-small {
          max-width: 100%;
        }
      }
    }
    &__show {
      display: inline-block;
    }
    &__hide {
      display: none;
    }
  }
  .gwhelp {
    margin: 0 16px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .helpHub {
      width: 120px;
      height: 40px;
      font-size: 1rem;
      line-height: 150%;
      font-family: "Open Sans", serif;
      font-weight: 700;
      border: 2px solid #057C9E !important;
      padding: 0 5px !important;
      margin-right: 0 !important;
      font-weight: 700 !important;
      color: #057C9E !important;
        &:hover, &:focus {
          color: #057C9E !important;
      }
    
      .helpIcon {
        position: relative;
        top: -2px;
        left: 5px;
      }
    }
}
  .gwhelp-icon {
    color: #007FA3;
    cursor: pointer;
    line-height: 26px;
    font-size: 30px;
}
}

.gwProfile {
  display: inline-block;
  &__dropcontent {
    @include absolute(top 65px right -170px);
    min-width: 155px;
    background: $pearson-white;
    font-size: 0.813rem;
    background: $pearson-white;
    color: $pearson-night-rider;
    padding: 3px 5px;
    border: solid 1px #e7e7e7;
    @include transition(right 0.5s ease-in-out);
    &__list {
      &__ahub{
        padding: 8px 10px;
        font-size: 0.875rem;
        text-transform: none !important;
      }
      @include dropdown-list();
    }
    & a {
      @include dropdown-list();
    }
  }
  &drop__visible {
    right: 1px;
  }
  &__dropicon {
    padding-right: 10px;
  }
  &__logout {
    padding: 10px 0px;
    color: $pearson-night-rider;
    text-transform: capitalize;
    display: block;
    & .usermenu__dropicon {
      width: 34px;
      height: 25px;
      padding-right: 18px;
    }
  }
  &-dropcontent {
    cursor: pointer;
  }
}

.gwAvatar {
  color: $pearson-night-rider;
  cursor: pointer;
.active-hub{
  background: #fff !important;
  width: 40px !important;
  height: 40px !important;
  font-size: 1rem !important;
  color: #057c9e !important;
  border: 3px solid #057c9e;
  @include OpenSansBold();
}
  &:hover {
    text-decoration: none;
    color: inherit;
  }
  &__img-blk {
    width: 46px;
    height: 46px;
    cursor: pointer;
    overflow: hidden;
    @include relative;
    background: #EA067E !important;
    @include border-radius(50% !important);
    display: flex !important;
    justify-content: center;
    align-items: center;
    color: $pearson-white;
    font-size: 1.25rem !important;
  }
  &__image {
    width: 100%;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    object-fit: contain;
    cursor: pointer;
  }
  &__text {
    color: $pearson-white;
    @include absolute(top 50% left 50%);
    font-size: 1rem;
    cursor: pointer;
    user-select: none;
    @include transform(translate(-50%, -50%));
  }
}

.material-icons {
  @include MaterialIcons;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.greetings{
  border-radius: 8px !important;
  top:64px;
  border: 1px solid #e9e9e9 !important;
  @include OpenSansSemibold();
  background: #fff !important;
  @include box-shadow(none);
  .toast-body{
      padding: 1rem 1.5rem;
      @include bp-small{
          display: flex;
          font-size: 0.75rem;
          align-items: center;
          padding: .75rem;
          white-space: nowrap;
      }
  }
}

@include keyframes(profileopen) {
  from {
    right: 0px;
  }
  to {
    right: 0px;
  }
}

@include keyframes(profileclose) {
  from {
    right: 0px;
  }
  to {
    right: -150px;
  }
}