@import '../../styles/customvariables';
@import '../../styles/custommixin';

.passwordchange{
    padding: 14px;
    &__heading{
        font-size:$fontsize;
        letter-spacing: 0px;
        color: $pearson-charcoal;
        opacity: 1;
        @include OpenSansExtraBold();
    }
    &__list{
        list-style-type: disc;
        padding-left: 1em;
        &--item{
            font-size:$fontsize;
            letter-spacing: 0px;
            color: $pearson-charcoal;
            opacity: 1;
            padding-top: 12px;
            @include OpenSansRegular();
        }
    }
    &__sublist{
        padding-left: 1em;
        list-style-type: circle;
        &--item{
            font-size:$fontsize;
            letter-spacing: 0px;
            color: $pearson-charcoal;
            opacity: 1;
            padding-top: 12px;
            @include OpenSansRegular();
        } 
    }
}
.toggle-pwd-btn{
    @include absolute(right 20px top 32px);
    padding: 0;
    &:disabled{
        opacity: 0.5;
        pointer-events: none;
    }
}
.instructor{
    background: $pearson-white 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #0000000D;
    border: 1px solid #E9E9E9;
    border-radius: 4px;
    height:100%;
    &__head{
        padding:16px 24px;
        border-bottom:0.5px solid #f0f0f0;
        &__txt{
            font-size:1.250rem;
            @include OpenSansSemibold();
            color: $default-pearson-blue;
            text-align: left;
            &--small{
                font-size:1rem;
            }
        }
        &resource{
            &__txt{
                font-size:$fontsize;
                @include OpenSansSemibold();
                color: #252525;
            }
        }
    }
    &__body{
        padding:24px;
        &__search{
            height:270px;
            position: relative;
            width: 100%;
        }
        .datepicker {
            .form__input {
                // background: url('/images/date-picker.svg') no-repeat;
                background-position: 95% center;
                margin-right: 10px;
            }
        }
    }
    &__foot{
        border-top: 0.5px solid#efefef;
        padding:16px 24px;
        &__data{
            font-size: $fontsize;
            color: #252525;
            @include OpenSansRegular();
            & span{
                @include OpenSansSemibold();
            }
        }
        .pagination__txt{
            border-bottom: 1px solid $pearson-charcoal;
        }
    }
    &__tab{
        &__head{
            padding: 1rem 0rem;
            border-bottom: 0.5px solid #e8e8e8;
        }
        &__body{
            margin-top: 24px;
        }
        &__title {
            text-align: left;
            letter-spacing: 0px;
            color: #252525;
            opacity: 1;
            font-size: 1rem;
            & span{
                @include OpenSansSemibold();
            }
        }
        &__icon{
            width: 18px;
            height: 18px;
        }
        &__btn{
            background-color: $transparent;
            line-height: 0;
        }
    }
    &__link{
        color: #007FA3;
        font-size: $fontsize;
        @include OpenSansSemibold();
        text-decoration: underline;
        white-space: nowrap;
        margin-right: 12px;
        cursor: pointer;
        padding: 4px 0;
        &:last-child{
            margin-right: 0px;
        }
    }
    &__label{
        font-size: 0.750em;
        color: #252525;
        @include OpenSansRegular();
        margin: 16px 0 0 12px;
    }
}

.organisation__cnt{
    width: 720px;
    height: 80px;
    // background: #FFFFFF url('/images/organisation-background.svg') 0px 6px no-repeat padding-box;
    box-shadow: 0px 1px 16px #0000001A;
    border-radius: 6px;
    margin:0 auto 18px;
    &txt{
        @include OpenSansRegular();
        font-size:1.500rem;
        color: #252525;
        margin: 0 24px;
    }
    &img{
        max-height: 80px;
        width:auto;
    }
}

.no__boxshadow{
    box-shadow: none;
    border: none;
}

.vl{
    border: 1px solid #E9E9E9;
}