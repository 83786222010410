@import '../../styles/custommixin';
@import '../../styles/customvariables';

.spalsh-body{
   padding: 0 65px 85px 65px;
    width: 100%;
    position: relative;
    transition: 0.5s ease;
    background-color: #f7f7f7;
        @include bp-extrasmall{
            padding: 0 18px;
            margin-bottom: 60px;
        }
}
.-spalsh-black-bold-xxl{
    font-size: 3rem;
    @include OpenSansBold;
    color: #1F2121;
    @include bp-extrasmall{
        margin: 0 20px;
    }
}
.-spalsh-black-reg-lg{
    font-size: 1.625rem;
    @include OpenSansRegular;
    color: $pearson-black;
    @include bp-extrasmall{
        margin: 0 20px;
    }
}
.spalshimg{
    @include bp-verysmall{
        width: 100%;
    }
}