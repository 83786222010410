.user-not-entitled-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  background: #f5f5f5;
  display: flex;
  justify-content: center;
}

.no-activehub-account-error {
  align-self: center;
}

iframe.hidden {
  display: none;
}
.tos-pos {
  position: fixed;
  top: 123px;
}

.piModalButtonDefault{
  text-transform: uppercase;
}