@import '../../styles/customvariables';
@import '../../styles/custommixin';
@import "~common-components-spa/dist/styles/main.scss";
@import "~common-components-spa/dist/styles/reset.scss";

.gwWraper_footer{
  z-index:4;
}

.gwFooter{
    background: #007FA3;
    padding: 20px 0;
    font-size: 0.875rem;
    color: #fff;
    width: 100%;
    &__links{
        width: 55%;
        @include bp-small{
            display: block;
            width: 100%;
            text-align: center;
            font-size: 0.75rem;
        }
        &__ul{
            float: right;
            margin: 0;
            @include bp-small{
                float: none;
                display: inline-block;
            }
        }
        &__li{
            float: left;
            &:last-child{
                &:after{
                    content: ' ';
                    padding: 0 10px;
                }
            }
            @include bp-small{
                float: unset;
                display: inline-block;
            }
            span{
                color: #fff;
                text-decoration: underline; 
                cursor: pointer;
                &:hover{
                    color: #fff;
                    text-decoration: underline;
                }
                &:focus {
                    outline: rgb(54, 141, 228) solid 2px;
                    outline-offset: 2px;
                    box-shadow: none;
                }
            }
            &:after{
                content: '|';
                padding: 0 10px;
                @include bp-medium{
                    padding: 0 4px;
                }
            }
        }
    }
    &__copy{
        width: 40%;
        @include bp-small{
            margin-top:10px;
            width: 100%;
            text-align: center;
        }
    }
    &__access{
        @include absolute(right 15px top 0);
        line-height: 1;
        &--ahub{
          right: 12%;
        }
        .gwaccess__link{
            color: #fff;
            text-decoration: underline;
            font-size: 1.125rem;
            cursor: pointer;
        }
        .gwaccess__dropdown{
            @include absolute(right 0 bottom 50px);
            min-width: 200px;
            &--style{
                border: 1px solid #007FA3;
                border-radius: 10px;
                text-align: center;
                background: #fff;
                font-size: 0.875rem;
                width: 100%;
                li{
                    border-bottom: solid 1px #ececec;
                    padding: 15px 10px;
                    color: #707070;
                    cursor: pointer;
                }
            }
            &--size{
                border: 1px solid #007FA3;
                border-radius: 10px;
                background: #fff;
                margin-top: 10px;
                width: 100%;
                label{
                    color: #707070;
                    margin: 10px;
                }
                .gwfontsize__decrease{
                    button{
                        color: #007FA3;
                        font-size: 2rem;
                        cursor: pointer;
                    }
                }
                .gwfontsize__increase{
                    font-size: 1.125rem;
                    button{
                        color: #007FA3;
                        font-size: 2rem;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
.gwbutton{
  background: transparent;
}
.gwlabel{
  margin: 0;
}
.disable__fontoption button.gwbutton {
  color: #E7E7E7 !important;
	pointer-events: none !important;
}


.gwFooter_d_one {
  display: none !important;
}

.gwFooter_ml_0 {
  margin-left: 0 !important;
}

.gwFooter_align_items_center {
  align-items: center !important;
}

.gwFooter_row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.gwFooter_py_1 {
  padding-bottom: 0.25rem !important;
  padding-top: 0.25rem !important;
}

.gwFooter_justify_content_center {
  justify-content: center !important;
}

.gwFooter_border_right {
  border-right: 1px solid #dee2e6 !important;
}

.gwFooter_col_6 {
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.gwFooter_d_flex {
  display: flex !important;
}

.gwbutton {
  background: transparent;
}

.gwlabel {
  margin: 0;
}

/*# sourceMappingURL=gw-footer.css.map */
