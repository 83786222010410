@import '../../styles/customvariables';
@import '../../styles/custommixin';
.live-chat-button{
    z-index: 1080;
    position: fixed;
    right: 0;
    bottom: 0;
    background-color: #003057;
    color: #fff;
    cursor: pointer;
    height: auto;
    border-radius: 37px;
    font-weight: 400;
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-right: 10px;
    border: solid 2px #003057;
    button{
        padding: 10px 24px;
        background-color: transparent;
        color: #fff;
        @include bp-small{
            padding: 0px 10px;
        }
    }
}