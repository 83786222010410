@import "../../styles/customvariables";
@import "../../styles/custommixin";

.ogaWrapper {
    &__bdy {
        grid-template-rows: minmax(70px, auto);
    }
}
.preference_wrapper {
    width: 100% !important;
    @include bp-small {
        max-width: 370px !important;
    }
}

.-toggleSpanPos {
    right: 26px;
}
.preference-heading {
    .goto__link {
        color: #007fa3;
        font-size: 0.75rem;
        font-family: "Open Sans", serif;
        font-weight: 400;
        text-decoration: underline;
        cursor: pointer;
        background-color: transparent;
        padding: 8px 0px 10px;
        @include bp-verysmall {
            padding-top: 45px;
        }
    }
    h1 {
        font-size: 1.125rem;
        font-family: "Open Sans", serif;
        font-weight: 600;
        color: #252525;
        padding-bottom: 8px;
    }
}
.instructor {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #0000000d;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    height: 100%;
    .form-control:disabled,
    .form-control[readonly] {
        background: #e9e9e980 0% 0% no-repeat padding-box;
        border: 0.5px solid #c7c7c7;
        color: #c7c7c7;
    }
    .button--v2 {
        &__primary {
            background-color: #047b9c;
            border: 1px solid #047b9c;
            color: #ffffff;
            outline: unset;
            box-shadow: unset;
            &.disable {
                background-color: #b2d8e3;
                border-color: #b2d8e3;
                pointer-events: none;
                color: #fff;
            }
        }
        &__bordered {
            background-color: transparent;
            border: 1px solid #d9d9d9;
            color: #252525;
            outline: unset;
            box-shadow: unset;
            &:focus {
                outline: solid 2px #368de4 !important;
            }
        }
    }
    &__head {
        padding: 16px 24px;
        border-bottom: 0.5px solid #f0f0f0;
        &__txt {
            font-size: 1.25rem;
            font-family: "Open Sans", serif;
            font-weight: 600;
            color: #007fa3;
            text-align: left;
        }
    }
    &__body {
        padding: 24px;
        min-height: 480px;
    }
    .d-flex {
        display: flex !important;
    }
    .vl {
        border: 1px solid #e9e9e9;
    }
}
.gwSpaWrapper {
    &__tabs {
        &--ahub {
            & .gwTab {
                @include bp-small {
                    top: 0px !important;
                    width: 65% !important;
                    z-index: 9999 !important;
                }
                @include bp-verysmall {
                    background-color: #fff !important;
                    top: 62px !important;
                    width: 100% !important;
                    z-index: 2 !important;
                }
            }
        }
    }
    &__iframe {
        &--ahub {
            & .ogaWrapper {
                &--ahub {
                    & .gwSpaWrapper {
                        &__tabs {
                            &--ahub {
                                & .gwSpaWrapper {
                                    &__iframe {
                                        & .tab-pane {
                                            @include bp-verysmall {
                                                padding-top: 62px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
