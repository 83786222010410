@import "../../styles/customvariables";
@import "../../styles/custommixin";
@import "~common-components-spa/dist/styles/main.scss";
@import "~common-components-spa/dist/styles/reset.scss";

.info-tooltip{
    position: relative;
    &:hover{
        .info-tooltip__data{
            display: block;
        } 
    }
    &__data{
        background: #252525;
        color: #fff;
        left: 170%;
        font-size: 0.75rem;
        @include OpenSansRegular();
        width: 248px;
        border: solid thin #252525;
        min-height: 50px;
        padding: 10px;
        @include border-radius(4px);
        @include absolute(top -15px right -12px);
        display: none;
        z-index: 4;
        &:before{
            content: "";
            display: block;
            width: 0; 
            height: 0; 
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-right: 8px solid #252525;
            @include absolute(top 15px left -9px);
        }
        &--access{
            left: -80px;
            top: 30px;
            width: 170px;
            min-height: 43px;
            &:before{
                transform: rotate(90deg);
                top: -13px;
                left: 92px;
            }
        }
        &--right{
            right: 0px;
            top: -6px;
            width: 65px;
            min-height: 24px;
            padding: 2px 4px;
            &:before{
                transform: rotate(0deg);
                top: 3px;
                left: -9px;
            }
        }
        &--top{          
            min-height: 20px;
            width: auto;
            min-width: 40px;
            padding: 0;
            margin: 0 -10px;
            text-align: center;
            @include border-radius(4px);
            @include absolute(top -26px left 5px);           
            &:before {
                transform: rotate(-90deg);
                top: 12px;
                left: 45%;
                bottom: 0px;
                right: 0;
            }
        }
        &--topcr{
            width: 120px;
            background-color: #252525;
            color: #fff;
            text-align: center;
            @include border-radius(6px);
            padding:0;           
            z-index: 1;
            right: unset;
            top: unset;
            min-height:unset;
            @include absolute(bottom 110% left 50%);   
            margin-left: -60px;
            transition: opacity 0.3s;

            &:before{
                content: "";
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: #252525 transparent transparent transparent;
            }
            & .info-tooltip__text{
                width: 90px;
            }
        }
        &--bottom{
            @include absolute(top 2.25rem left -35px); 
            width: 90px;
            min-height: 1.25rem;
            padding: 0 0.625rem;
            &::before{
                transform: rotate(90deg);
                top: -10px !important;
                left: 2.625rem !important;
            }
            &__cr{
                @include absolute(top 40px left 0px);
                max-width: 90px;
                min-width: 50px;
                text-align: center;
                min-height: 20px;
                padding: 0 10px;
                &::before{
                    transform: rotate(90deg);
                    top: -10px;
                    left: 15px;
                }
            }
            &.-headerbottom{
                padding: 2px 4px;
                @include absolute(top 1.25rem left 0px);
                width: 100%;
                min-height: 1.25rem;
                .info-tooltip__tooltext{
                    @include multi-line-ellipsis(2);
                    font-size: 0.675rem;
                }
            }
        }
        &--board{
            width: auto;
            min-height: 1.25rem;
            padding: 0;
            @include absolute(top 2.25rem left -14px); 
            &::before{
                transform: rotate(90deg);
                top: -10px;
                left: 1.6875rem;
            }
            &.boardleftside{               
                top: 42px;
                &::before {
                    top: -12px;
                    left: 2.25rem;
                }
            }
        }
        h6{
            font-size: 0.625rem;
            padding-bottom: 8px;
        }
        ol{
            padding-left: 15px;
            li{
                padding-bottom: 8px;
                &:last-child{
                    padding-bottom: 0;
                }
            }
        }       
    }
    &.copy-tooltip{
        .info-tooltip__data{
            background: #007FA3;
            border: solid thin #007FA3;
            width: 310px;
            min-height: 40px;
            top: -8px;
            &:before{
                border-right: 8px solid #007FA3;
                top: 10px; 
            }
        }
    }
    &__text{
        font-size: $fontsize;
        max-width: 80px;
        min-width: 40px;
        @include multi-line-ellipsis(2);
        word-break: break-word;
        @include OpenSansRegular();
        color:  $pearson-charcoal;
        &--textoverflow{
            width: 90px;
            max-width: unset;
            @include bp-large { 
                width: 42px;
            }
            @include bp-verysmall { 
                width: 72px;
            }
            @include bp-extrasmall { 
                width: 42px;
            }
            
            &.endcall{
                width: 110px;
            }
        }
    }
    & img{
        width: auto;
        height: 24px;
    }
    &__border{
        border: 1px solid #d4d4d4;
        margin: 0 0.4rem;
        height: 21px;
    }
    &__num{
        color: #005D83;
        font-size: $fontsize;
        @include OpenSansSemibold();
        @include multi-line-ellipsis(1);
        width: auto;
        display: unset;
        max-width: 38px;
    }
    &__eltime{
        font-size: $fontsize;
        color: $pearson-charcoal;
        opacity: 1;
        @include OpenSansRegular();
    }
    &__tooltext{
        @include multi-line-ellipsis(1);
    }
    &__iconsfill{
        cursor: pointer;
        background-color: transparent;
        & .moredropToggleBtn {
            margin-left: 0;
        }
    }
    &.-pagination{
        & .info-tooltip__iconsfill{
            border: 1px solid transparent;
        }
        &:hover{
            & .info-tooltip__iconsfill{
                border: 1px solid #ededed;
                background: #e9e9e9;
                border-radius: 2px;
            }
        }
        & .info-tooltip__data--board{
            margin: 0.75rem 0.5rem;
            text-align: center;
            &:before{
                left: 1.5rem;
            }
        }
    }
    &.-whiteboardtooltip{
        & .info-tooltip__iconsfill {
            border: 1px solid transparent;
            width: 2.5rem;
            height: 2.5rem;
            display: flex !important;
            justify-content: center;
            align-items: center;
            &.nonactive{
                &::before {
                    position: absolute;
                    bottom: 0px;
                    right: 0px;
                    content: "";
                    width: 5px;
                    height: 6px;
                    // background-image: url('/images/more-option-indicator-2.svg');
                    background-repeat: no-repeat;
                }
            }   
            &.active{
                &::before {
                    position: absolute;
                    bottom: 0px;
                    right: 2px;
                    content: "";
                    width: 5px;
                    height: 6px;
                    // background-image: url('/images/more-option-indicator.svg');
                    background-repeat: no-repeat;
                }
            }
        }
       
        
        &:hover {
            & .info-tooltip__iconsfill {
                border: 1px solid #ededed;
                background: #e9e9e9;
                @include border-radius(2px);
            }
        }
    }
}

.note-tooltip{
    & .info-tooltip__data {
        width: 260px;
        min-height: auto;
        left: 155%;
    }
}
