$fontsize:0.875rem;
$fontweight:600;

// Standard Colors //
$default-pearson-blue:#007fa3;
$digital-pearson-blue: #047b9c;
$pearson-ink-blue: #005a70;
$pearson-white: #ffffff;
$pearson-black: #000000;
$pearson-grey: #cccccc;
$pearson-white-grey: #f5f5f5;
$pearson-sunshine-yellow: #ffb71c;
$pearson-sunflower-yellow: #ff9b19;
$pearson-digital-ice-blue: #daf0ed;
$pearson-digital-marine-turquoise: #19a5a3;
$pearson-charcoal: #252525;
$pearson-medium: #6a7070;
$pearson-concrete: #c7c7c7;
$pearson-alto: #d9d9d9;
$pearson-moonlight: #e9e9e9;
$pearson-strawberry-red: #db0021;
$pearson-digital-grass-green: #038238;
$pearson-hot-pink: #da0473;
$pearson-pink-swan:#b4b4b4;
$pearson-night-rider: #333333;
$pearson-snuff :#dfdedf;
$pearson-boston-blue:#4893b2;
$pearson-cerulean:#047A9C;
$pearson-gainsboro:#d8d8d8;
$pearson-lavender:#f0f0f1;
$pearson-link-water:#ced4da;
$pearson-alice-blue:#edf3f5;
$pearson-gun-powder:#4c4c4e;
$pearson-aqua-spring:#e8e9e8;
$pearson-oyster-bay:#d8edec;
$pearson-gull-grey:#a6a9aa;
$pearson-ivory:#f4f4f3;
$pearson-zircon:#e2e3e3;
$pearson-submarine:#9d9e9e;
$pearson-tuna:#495057;
$pearson-san-juan:#46515a;
$digital-pearson-blue:#047b9c;
$pearson-common-grey:#979797;
$pearson-mercury:#e8e8e8;

// Alert Dialog Colors, Spacing and Font Size Variations //
$alert-dialog: #c7c7c7;
$alert-dialog-success: #19a5a3;
$alert-dialog-warning: #db0021;
$alert-dialog-info: #eea521;
$alert-icon-font-size: 30px;
$alert-dialog-padding: 24px 28px;
$alert-dialog-borderradius: 2px;
$alert-left-border-width:3px;
$alert-border-width:1px;
$alert-icon-text-margin: 0 12px;

// Gloabal Variable //
$transparent:transparent;

// Standard Button Colors And Size Variations //
$small-fontsize-button:1rem;
$small-lineheight-button:32px;
$large-fontsize-button:1em;
$large-lineheight-button:36px;
$exlarge-fontsize-button:1em;
$exlarge-lineheight-button:45px;
$primary-button-color: #ffffff;
$primary-button-bgr: #047b9c;
$primary-button-border: #047b9c;
$primary-button-bg-hover: #005a70;
$primary-button-hover-border: #005a70;
$bordered-button-color: #252525;
$bordered-button-bg: transparent;
$bordered-button-border: #d9d9d9;
$bordered-button-hover-color: #d9d9d9;
$bordered-button-bg-hover: #047b9c;
$bordered-button-hover-border: #047b9c;
$default-button-color: #252525;
$default-button-bg: #e9e9e9;
$default-button-border: #c7c7c7;
$default-button-bg-hover: #d9d9d9;
$default-button-hover-border: #d9d9d9;


// Form Font, Color and Spacing Variations //
$form-background:#ffffff;
$form-label-color: #848a89;
$form-input-border-color: #dadada;
$form-radio-border-color: #3b7a9c;
$form-checkbox-border-color: #979797;
$form-checkbox-disabled-border-color: rgba(0, 0, 0, 0.26);
$form-label-font-size: 0.815em;
$form-radiolabel-background:#6a706f;
$form-material-color:#57b3a6;
$form-material-text-color:#555555;
$form-material-checkbox-color:#333333;
$form-material-checkbox--background-color:#808080;
$form-material-checkbox-disabled-color: #6c757d;
$form-material-border-color:rgba(0, 0, 0, 0.54);
$form-input-invalid-color: #db0021;
$form-input-invalid-boxshadow-color:rgba(219, 0, 33, 0.6);
$form-checkbox-checked-background-color: #57b3a6;
$form-select-border-color: #80bdff;
$form-color:rgba(0, 0, 0, 0);
$form-toggle-border-color:#848484;

//Scrollbar Variable
$scrollbar-box-shadow:rgba(0,0,0,0.3);

//Gateway Color, Fonts Variables
$gateway-cape-cod:#4F5454;
$gateway-dove-gray:#707070;
$gateway-alabaster: #f9f9f9;
$tooltip-border-clr:$default-pearson-blue;
$tooltip-txt-clr : #6A7070;
