@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap');

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(220, 80);
  }
}
@keyframes radioripple {
  0% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
  }
  50% {
    box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0);
  }
}
@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(220, 80);
  }
}
@keyframes radioripple {
  0% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
  }
  50% {
    box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0);
  }
}
.button {
  background: transparent;
  border: transparent;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  border-radius: 40px;
  padding: 0 20px;
  font-size: 1rem;
  line-height: 30px;
  min-height: 32px;
  font-family: "Open Sans", serif;
  font-weight: 600;
}
.button:last-child {
  margin-left: 12px;
}
.button.btnwidth {
  min-width: 130px;
  min-height: 40px;
}
.button.modalbtn {
  min-width: 125px;
  font-size: 0.875rem;
}
.button.dropdownbtn {
  min-width: 95px;
  font-size: 0.875rem;
}
.button.small {
  font-size: 0.875rem;
}
.button.btnsquare {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}
.button__primary {
  background-color: #047b9c;
  border: 1px solid #047b9c;
  color: #ffffff;
}
.button__primary:disabled, .button__primary.disable {
  background-color: #b2d8e3;
  border-color: #b2d8e3;
  pointer-events: none;
}
.button__primary:hover {
  background-color: #005a70;
  border: 1px solid #005a70;
  color: #ffffff;
}
.button__primary__bordered {
  border: 1px solid #047b9c;
  background-color: #fff;
  color: #047b9c;
}
.button__primary__bordered:hover {
  background-color: #005a70;
  border: 1px solid #005a70;
  color: #ffffff;
}
.button__primary__bordered:disabled, .button__primary__bordered.disable {
  border-color: #007FA3;
  pointer-events: none;
  color: #007FA3;
  opacity: 0.5;
}
.button__bordered {
  background-color: transparent;
  border: 1px solid #d9d9d9;
  color: #252525;
}
.button__bordered:hover {
  background-color: #d9d9d9;
  border: 1px solid #d9d9d9;
}
.button__default {
  background-color: #e9e9e9;
  border: 1px solid #c7c7c7;
  color: #252525;
}
.button__default:hover {
  background-color: #d9d9d9;
  border: 1px solid #d9d9d9;
  color: #252525;
}

.button--v2 {
  background: transparent;
  border: transparent;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  border-radius: 40px;
  padding: 0 20px;
  font-size: 1rem;
  line-height: 32px;
  min-height: 32px;
  font-family: "Open Sans", serif;
  font-weight: 600;
}
.button--v2:last-child {
  margin: 0 0 0 12px;
}
@media only screen and (max-width: 991px) {
  .button--v2__imgfirst.--filtermargin {
    margin-bottom: 5px;
  }
}
.button--v2.btnwidth {
  min-width: 130px;
  min-height: 40px;
}
.button--v2.rosterbtn {
  min-width: 128px;
  min-height: 32px;
  line-height: 30px;
}
.button--v2.instructorbtn {
  min-width: 114px;
  line-height: 30px;
}
.button--v2.crttskbtn {
  display: none;
}
@media only screen and (max-width: 767px) {
  .button--v2.crttskbtn {
    display: grid;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }
  .button--v2.crttskbtn:last-child {
    margin-top: 1.5rem;
    margin-left: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .button--v2.savetskbtn {
    margin-bottom: 16px;
  }
}
.button--v2.resourcesbtn {
  min-width: 156px;
  line-height: 36px;
}
.button--v2.modalbtn {
  min-width: 130px;
  font-size: 0.875rem;
}
@media only screen and (max-width: 767px) {
  .button--v2.modalbtn {
    min-width: 128px;
    margin: 0px 4px;
  }
}
.button--v2.dropdownbtn {
  min-width: 110px;
  font-size: 0.875rem;
}
.button--v2.assignmentbtn {
  min-width: 80px;
  font-size: 0.875rem;
}
.button--v2.small {
  font-size: 14px;
}
.button--v2.rosterbtnTab {
  min-width: 128px;
  min-height: 32px;
  font-size: 1.125rem;
}
.button--v2.rosterbtnMbTab {
  min-width: 128px;
  min-height: 32px;
  font-size: 1.125rem;
  margin: 8px 0;
}
.button--v2.btnsquare {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
.button--v2.locale-btn {
  color: #252525;
  font-family: "Open Sans", serif;
  font-weight: 400;
  border: 1px solid #007FA3;
}
.button--v2.btn36 {
  height: 36px;
}
.button--v2.tasksbtn {
  min-width: 200px;
  height: 36px;
}
.button--v2.btnIcon {
  margin-right: 15px;
  padding: 0px;
}
.button--v2.btnIcon:last-child {
  margin: 0px;
}
.button--v2.roster-land-btn {
  color: #252525;
  border: 1px solid #007FA3;
  border-radius: 8px;
  font-size: 0.875rem;
}
.button--v2.-taskFilBtn {
  background-color: transparent;
}
.button--v2.-taskFilBtn:before {
  background: unset;
}
.button--v2.-taskFilBtn:hover {
  color: #047a9c;
  cursor: pointer;
}
.button--v2.-taskFilBtn:hover:before {
  background-color: unset;
}
.button--v2__primary {
  background-color: #047b9c;
  border: 1px solid #047b9c;
  color: #ffffff;
}
.button--v2__primary:disabled, .button--v2__primary.disable {
  background-color: #b2d8e3;
  border-color: #b2d8e3;
  pointer-events: none;
}
.button--v2__primary:before {
  background: #005a70;
}
.button--v2__primary:hover {
  color: #ffffff;
}
.button--v2__primary:hover:before {
  background-color: #005a70;
}
.button--v2__primary__bordered {
  border: 1px solid #047b9c;
  background-color: #fff;
  color: #047b9c;
}
.button--v2__primary__bordered:before {
  background: #005a70;
}
.button--v2__primary__bordered:hover {
  color: #ffffff;
}
.button--v2__primary__bordered:hover:before {
  background: #005a70;
}
.button--v2__primary__bordered:disabled, .button--v2__primary__bordered.disable {
  border-color: #007FA3;
  pointer-events: none;
  color: #007FA3;
  opacity: 0.5;
}
.button--v2__bordered {
  background-color: transparent;
  border: 1px solid #d9d9d9;
  color: #252525;
}
.button--v2__bordered:before {
  background: transparent;
}
.button--v2__bordered:hover {
  border: 1px solid #d9d9d9;
}
.button--v2__bordered:hover:before {
  background: #d9d9d9;
}
.button--v2__bordered:disabled, .button--v2__bordered.disable {
  pointer-events: none;
  opacity: 0.5;
}
.button--v2__default {
  background-color: #e9e9e9;
  border: 1px solid #c7c7c7;
  color: #252525;
}
.button--v2__default:hover {
  background-color: #d9d9d9;
  border: 1px solid #d9d9d9;
  color: #252525;
}
.button--v2__default:disabled, .button--v2__default.disable {
  pointer-events: none;
  opacity: 0.5;
}
.button--v2__img {
  margin: 0 0 0 15px;
}
.button--v2__link {
  padding: 0;
  font-weight: 400;
  font-size: 0.875rem;
  overflow: visible !important;
}
.button--v2__tablebtn {
  color: #0479AC;
  font-size: 0.875rem;
  text-decoration: underline;
  font-family: "Open Sans", serif;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  border-radius: 0;
}
.button--v2__notablebtn {
  color: #252525;
  font-size: 0.875rem;
  font-family: "Open Sans", serif;
  font-weight: 400;
  pointer-events: none;
}
.button--v2.-stuBtn {
  font-family: "Open Sans", serif;
  font-weight: 400;
  font-size: 0.875rem;
  width: 202px;
  min-height: 36px;
}

.ripple--v2 {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  overflow: hidden;
  -webkit-transition: border-color 0.3s, color 0.3s;
  transition: border-color 0.3s, color 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.ripple--v2:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  -webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -3em, 0);
  transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -3em, 0);
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transition: -webkit-transform 0.7s, opacity 0.7s, background-color 0.7s;
  transition: transform 0.7s, opacity 0.7s, background-color 0.7s;
}
.ripple--v2:after {
  content: "";
  top: 50%;
  left: 50%;
  position: absolute;
  width: 1px;
  height: 1px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 1;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}
.ripple--v2:focus:after {
  animation: ripple 1s ease-out;
}
.ripple--v2:hover:before {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.creation {
  height: 100vh;
}

@media only screen and (max-width: 767px) {
  .creation {
    height: 100%;
  }
}

.creation__wrapleft {
  padding: 16px 48px;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-flow: column;
}
@media only screen and (max-width: 767px) {
  .creation__wrapleft {
    padding: 16px 16px 0;
  }
}
.creation__logo img {
  cursor: pointer;
}
.creation__banner {
  max-width: 500px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 991px) {
  .creation__banner {
    max-width: 100%;
    margin: 0 auto;
  }
}
.creation__banner img {
  max-width: 100%;
  height: auto;
}

@media only screen and (max-width: 991px) {
  .creation__banner img {
    max-width: 100%;
    height:auto;
  }
}

@media only screen and (max-width: 576px) {
  .col-right{
    height: 100%;
  }
  .creation__banner img {
    max-width: 100%;
    height:auto;
  }
}

.creation__wrap {
  background-color: #003057;
  padding: 98px 148px;
  height: 100%;
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 1200px) {
  .creation__wrap {
    padding: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .creation__wrap {
    padding: 24px 16px;
    justify-content: start;
    position: relative;
    height: 100%;
    width: 100%;
  }
}
.creation__wrap .create-btn {
  color: #ffffff;
  max-width: 300px;
  width: 100%;
  display: block;
  padding: 15px;
  margin-top: 32px;
  font-family: "Open Sans", serif;
  font-weight: 600;
  font-size: 1rem;
  min-height: 45px;
  line-height: 0;
  opacity: 0.8;
  margin-left: 0;
}
.creation__wrap .create-btn:hover:before {
  background: #ffffff;
}
.creation__wrap .create-btn:hover {
  color: #003057;
  background: #ffffff;
  opacity: 1;
  border-radius: 40px;
}
.creation__wrap .create-btn:focus {
  color: #003057;
  background: #ffffff;
  opacity: 1;
  border-radius: 40px;
}
@media only screen and (max-width: 767px) {
  .creation__wrap .create-btn {
    margin-top: 12px;
  }
}
.creation__wrap__head {
  font-size: 1.5rem;
  color: #ffffff;
  font-family: "Playfair Display", serif;
  font-weight: 400;
  font-weight: bold;
  text-align: center;
  margin: 0;
}
@media only screen and (max-width: 767px) {
  .creation__wrap__head {
    font-size: 2.375rem;
  }
}

/*# sourceMappingURL=creation-primary.css.map */
