@import "~common-components-spa/dist/styles/main.scss";

.gwWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f9f9f9;
  &_header {
    background-color: #282c34;
    min-height: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  &_content {
    flex: 1;
    // margin: 0 auto;
    // the whole width should be available for the spa
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &_footer {
    background-color: #282c34;
    min-height: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
}

.skipnav a{ 
  position: absolute; 
  left: -10000px; 
  width: 1px; 
  height: 1px; 
  overflow: hidden;
  color: #007FA3;
  background-color: #fff;
  &:focus, &:active,&:hover{ 
    position: absolute; 
    z-index: 9999;
    left: 60px;
    width: auto; 
    height: auto; 
    overflow: visible; 
    text-decoration: underline; 
    color: #007FA3;
    background-color: #fff;
  }
}