@import './custommixin';
@import './customvariables';

.button {
    background: $transparent;
    border: $transparent;
    border-radius: 3px;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    border-radius: 40px;
    padding: 0 20px;
    font-size:$small-fontsize-button;
    line-height:$small-lineheight-button;
    @include OpenSansSemibold;
    &.btnwidth{
       min-width:130px; 
       min-height: 40px;
    }
    &.btnsquare{
        @include border-radius(3px);
    }
    &__primary{
        background-color: $primary-button-bgr;
        border: 1px solid $primary-button-border;
        color: $primary-button-color;
    }
    &__primary:hover{
        background-color: $primary-button-bg-hover;
        border: 1px solid $primary-button-hover-border;
        color: $primary-button-color;
    }
    &__bordered{
        background-color: $bordered-button-bg;
        border: 1px solid $bordered-button-border;
        color: $bordered-button-color;
    }
    &__bordered:hover{
        background-color: $bordered-button-hover-color;
        border: 1px solid $bordered-button-border;
    }
    &__default{
        background-color: $default-button-bg;
        border: 1px solid $default-button-border;
        color: $default-button-color;
    }
    &__default:hover{
        background-color: $default-button-bg-hover;
        border: 1px solid $default-button-hover-border;
        color: $default-button-color;
    }
}

@include ripple-effect();



