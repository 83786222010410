.ogaWrapper {
    &__bdy {
        grid-template-rows: minmax(70px, auto);
    }
}
.user_profile_wrapper {
    margin: 125px 0 55px 0;
    @media screen and (max-width: 991px){
        margin: 24px 0 24px 0;
    }
}
.instructor {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #0000000d;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    height: 100%;
    .form-control:disabled,
    .form-control[readonly] {
        background: #e9e9e980 0% 0% no-repeat padding-box;
        border: 0.5px solid #c7c7c7;
        color: #c7c7c7;
    }
    .button--v2 {
        &__primary {
            background-color: #047b9c;
            border: 1px solid #047b9c;
            color: #ffffff;
            outline: unset;
            box-shadow: unset;
            &.disable {
                background-color: #b2d8e3;
                border-color: #b2d8e3;
                pointer-events: none;
                color: #fff;
            }
        }
        &__bordered {
            background-color: transparent;
            border: 1px solid #d9d9d9;
            color: #252525;
            outline: unset;
            box-shadow: unset;
        }
    }
    &__head {
        padding: 16px 24px;
        border-bottom: 0.5px solid #f0f0f0;
        & .-instrHeadAlignment{
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            @media screen and (max-width: 450px) {
                flex-direction: column;
                justify-content: start;
                align-items: flex-start;
            }
        }
        &__txt {
            font-size: 1.25rem;
            font-family: "Open Sans", serif;
            font-weight: 600;
            color: #007fa3;
            text-align: left;
            padding-bottom: 0rem;
            @media screen and (max-width: 450px) {
                padding-bottom: 1rem;
            }
        }
    }
    &__body {
        padding: 24px;
        & .user-profile {
            & .form__input{
                @media screen and (max-width: 420px) {
                    text-overflow: ellipsis;
                }
            }
        }
    }
    .d-flex {
        display: flex !important;
    }
    .vl {
        border: 1px solid #e9e9e9;
    }
}
