@import '../../styles/custommixin';
@import '../../styles/customvariables';
@import '../../styles/button';
//@import '../../styles/modal';

/*Popup - pearson admin logout styling start*/
.gwLogoutPopup
{
    .modal-content{
        padding: 0;
    }
    .modal-title{
        font-size: 1.25rem;
    }
    .close{
        color: #777777;
        cursor: pointer;
        font-size: 2rem;
    }
    &__header{
        border: 0;
        padding: 0.7rem 1.5rem !important;
    }
    &__body{
        background-color: #f2f2f2 !important;
        text-align: center;
        padding-top: 2rem !important;
        &--text
        {
            font-size: 1em;
            color: #777777; 
        }
    }
    &__footer{
        background-color: #f2f2f2 !important;
        border: 0;
        padding: 0 0 3rem !important;
    } 
}
/*Popup - pearson admin logout styling end*/